import request from './request.js';
import public_request from './public_request'

//获取接口列表
export function apislist({ applicationId,version,pageNo,pageSize }) {
    return request({
        url: '/application-management/apis',
        method: 'get',
        params: public_request({
            applicationId,
            version,
            pageNo,
            pageSize
        })
    })
}
//添加接口
export function apiAddlist({ applicationId,version,name,method,path }) {
    return request({
        url: '/application-management/api',
        method: 'post',
        data: public_request({
            applicationId,
            version,
            name,
            method,
            path
        })
    })
}
//编辑接口
export function apiEditlist({ apiId,name,method,path }) {
    return request({
        url: '/application-management/api',
        method: 'put',
        data: public_request({
            apiId,
            name,
            method,
            path
        })
    })
}
//删除接口
export function apiDeletelist({ apiId }) {
    return request({
        url: '/application-management/api',
        method: 'delete',
        data: public_request({
            apiId
        })
    })
}