<template>
  <div class="content">
    <p style="margin-bottom:20px">
      <el-page-header
        content="设置"
        @back="goBack"
      />
      <!-- <router-link to="/application" style="color:#fff">
        <el-button type="primary">返回应用管理</el-button>
      </router-link> -->
    </p>
    <div class="left-tabs">
      <el-tabs
        v-model="default1"
        type="border-card"
        @tab-click="changeTabs"
      >
        <el-tab-pane
          label="页面"
          name="one"
        >
          <Page
            v-if="default1 == 'one'"
            ref="page"
          />
        </el-tab-pane>
        <el-tab-pane
          label="菜单"
          name="two"
        >
          <Menu
            v-if="default1 == 'two'"
            ref="menus"
          />
        </el-tab-pane>
        <el-tab-pane
          label="功能"
          name="three"
        >
          <Features
            v-if="default1 == 'three'"
            ref="features"
            :version="version"
          />
        </el-tab-pane>
        <el-tab-pane
          label="接口"
          name="four"
        >
          <Interface
            v-if="default1 == 'four'"
            ref="interface"
            :version="version"
          />
        </el-tab-pane>
      </el-tabs>
      <div class="right-version">
        <div class="add-icon">
          <div class="add-name">
            资源版本
          </div>
          <el-popconfirm
            confirm-button-text="确定"
            cancel-button-text="取消"
            icon="el-icon-info"
            icon-color="#409eff"
            title="确定要添加新版本"
            @confirm="addNewVersion"
          >
            <div
              slot="reference"
              class="add-icon"
            >
              <i class="el-icon-plus" />
            </div>
          </el-popconfirm>
        </div>
        <el-select
          v-model="version"
          style="width:100px"
          size="mini"
          placeholder="请选择"
          @change="changVersion"
        >
          <el-option
            v-for="(item, index) in versionOption"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>
<script>
import Page from "./module/page";
import Menu from "./module/menu";
import Features from "./module/features";
import Interface from "./module/interface";
import { resourceVersion } from "@/api/public.js";
import { addVersionNum } from "@/api/management";
export default {
  components: {
    Page,
    Menu,
    Features,
    Interface,
  },
  data() {
    return {
      default1: "one",
      version: "",
      application: "",
      versionOption: [],
    };
  },
  watch: {
    version(val) {
      console.log(111);
      if (this.default1 == "one") {
        this.$nextTick(() => {
          this.$refs.page.init(val);
        });
      }
      if (this.default1 == "two") {
        this.$nextTick(() => {
          this.$refs.menus.init(val);
        });
      }
      if (this.default1 == "three") {
        this.$nextTick(() => {
          this.$refs.features.init(val);
        });
      }
      if (this.default1 == "four") {
        this.$nextTick(() => {
          this.$refs.interface.init(val);
        });
      }
      // if (this.default1 == "one") {
      //   console.log("监听version", 1111);
      //   this.$nextTick(() => {
      //     this.$refs.page.init(val);
      //   });
      // }
    },
  },
  created() {
    this.getVersion();
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.push({ path: "/application" });
    },
    addNewVersion() {
      console.log(1111);
      let version = this.versionOption[0] + 1;
      addVersionNum({
        applicationId: this.$router.currentRoute.query.id,
        version: version,
      }).then((res) => {
        if (res.data == 0) {
          this.getVersion();
          this.version = version;
          this.$message.success("添加版本成功！");
          // this.$nextTick(() => {
          this.changVersion();
          // });
        }
      });
    },
    changVersion() {
      console.log(this.version);
      // 当你切换的时候做子列表刷新
      if (this.default1 == "one") {
        this.$nextTick(() => {
          this.$refs.page.init(this.version);
        });
      }
      if (this.default1 == "two") {
        this.$nextTick(() => {
          this.$refs.menus.init(this.version);
        });
      }
      if (this.default1 == "three") {
        this.$nextTick(() => {
          this.$refs.features.init(this.version);
        });
      }
      if (this.default1 == "four") {
        this.$nextTick(() => {
          this.$refs.interface.init(this.version);
        });
      }
    },
    getVersion(item) {
      // 版本
      resourceVersion({
        applicationId: this.$router.currentRoute.query.id,
      }).then((res) => {
        if (res.code == 0) {
          this.versionOption = res.data.list;
          this.version = res.data.list[0];
        }
      });
    },
    changeTabs(tab) {
      // console.log('12121',tab.index)
      let index = tab ? tab.index : 0;
      if (index == 0) {
        this.$nextTick(() => {
          this.$refs.page.init(this.version);
        });
      }
      if (index == 1) {
        this.$nextTick(() => {
          this.$refs.menus.init(this.version);
        });
      }
      if (index == 2) {
        this.$nextTick(() => {
          this.$refs.features.init(this.version);
        });
      }
      if (index == 3) {
        this.$nextTick(() => {
          this.$refs.interface.init(this.version);
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
::v-deep .el-tabs__content {
  padding: 20px !important;
  height: calc(100vh - 210px);
  overflow: auto;
}
.content {
  padding: 24px;
}
.left-tabs {
  position: relative;
}
.right-version {
  position: absolute;
  right: 10px;
  top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .add-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
    .add-name {
      font-size: 13px;
      margin-right: 5px;
    }
    .add-icon {
      border: 1px solid #ccc;
      background-color: #fff;
      padding: 5px 5px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
</style>
