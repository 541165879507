import request from './request.js';
import public_request from './public_request'
//获取菜单列表
export function menulist({ applicationId,version }) {
    return request({
        url: '/application-management/menus',
        method: 'get',
        params: public_request({
            applicationId,
            version
        })
    })
}
//新增菜单列表
export function menuAddlist(data) {
    return request({
        url: '/application-management/menu',
        method: 'post',
        data: public_request(data)
    })
}
//编辑菜单列表
export function menuEditlist(data) {
    return request({
        url: '/application-management/menu',
        method: 'PUT',
        data: public_request(data)
    })
}
//删除菜单列表
export function menuDeletelist({ menuIds }) {
    return request({
        url: '/application-management/menu',
        method: 'DELETE',
        data: public_request({
            menuIds
        })
    })
}