<template>
  <div>
    <p style="margin-bottom:20px;">
      <span style="margin-right:20px">接口列表</span>
      <el-button
        type="primary"
        @click="dialogTableVisible = true"
      >
        添加接口
      </el-button>
    </p>
    <!-- 添加接口弹框 -->

    <el-drawer
      :title="ruleForm.id ? '编辑接口' : '新增接口'"
      :visible.sync="dialogTableVisible"
      direction="rtl"
      size="638px"
      :before-close="resetForm"
    >
      <div class="drawer-content">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
          label-position="top"
        >
          <el-form-item
            label="接口名称"
            prop="name"
          >
            <el-input
              v-model="ruleForm.name"
              maxlength="32"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="请求方式"
            prop="method"
          >
            <el-select
              v-model="ruleForm.method"
              style="width:100%"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in enType"
                :key="item.id"
                :label="item.enVal"
                :value="item.enVal"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="请求路径"
            prop="path"
          >
            <el-input
              v-model="ruleForm.path"
              maxlength="128"
              show-word-limit
            />
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <el-button
            type="primary"
            @click="submitForm"
          >
            保存
          </el-button>
          <el-button @click="resetForm">
            取消
          </el-button>
        </div>
      </div>
    </el-drawer>
    <!-- 接口列表渲染 -->
    <el-table
      v-loading="loading"
      v-tableHeight="{bottomOffset: 115}"
      :data="tableData"
      :header-cell-style="styleObj"
      border
      style="width: 100%"
      height="100px"
    >
      <el-table-column
        prop="name"
        label="接口名称"
      />
      <el-table-column
        prop="method"
        label="请求方式"
      />
      <el-table-column
        prop="path"
        label="请求路径"
      />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="medium"
            @click="handleClick(scope.$index, scope.row)"
          >
            编辑
          </el-button>
          <el-popconfirm
            title="确定要删除这个接口吗？"
            @confirm="remove(scope.$index, scope.row)"
          >
            <el-button
              slot="reference"
              type="text"
              size="medium"
              style="color:red;margin-left:10px"
            >
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 接口列表编辑按钮弹框 -->
    <!-- 接口列表分页 -->
    <div
      class="block"
      style="margin-top:20px;text-align:right"
    >
      <el-pagination
        :current-page="page"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="len"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import {
  apislist,
  apiAddlist,
  apiEditlist,
  apiDeletelist,
} from "@/api/management_apis";
import { type } from "@/api/public.js";
export default {
  data() {
    return {
      versionId: "",
      styleObj: {
        background: "#f5f7fa",
      },
      tableData: [],
      dialogTableVisible: false,
      ruleForm: {
        name: "",
        method: "",
        path: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入接口名称", trigger: "blur" },
          { min: 1, max: 32, message: "长度在32个字符以内", trigger: "blur" },
          { validator: this.formValidation.pagename, trigger: "blur" },
        ],
        path: [
          { required: true, message: "请输入请求路径", trigger: "blur" },
          { validator: this.formValidation.path, trigger: "blur" },
        ],
        method: [
          { required: true, message: "请选择请求方法", trigger: "blur" },
        ],
      },
      dialogTableVisible1: false,
      editForm: "",
      page: 1,
      len: 0,
      size: 10,
      loading: true,
      valueId: "",
      enType: [],
    };
  },
  mounted() {
    // this.dataxx();
    type({ dictionaryCode: "api-method" }).then((res) => {
      this.enType = res.data.list;
    });
  },
  methods: {
    init(id) {
      this.versionId = id;
      this.dataxx();
    },
    //渲染列表事件
    dataxx() {
      apislist({
        version: this.versionId,
        applicationId: this.$router.currentRoute.query.id,
        pageNo: this.page,
        pageSize: this.size,
      }).then((res) => {
        this.tableData = res.data.list;
        this.len = res.data.total;
        this.loading = false;
      });
    },
    //添加接口弹框中的保存事件
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.ruleForm.id) {
            this.ruleForm.apiId = this.ruleForm.id;
            apiEditlist(this.ruleForm).then((res) => {
              console.log(res);
              if (res.code == 0) {
                // this.dialogTableVisible = false;
                this.resetForm()
                this.dataxx();
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
                
              } else {
                this.$message.error(res.error);
              }
            });
          } else {
            this.ruleForm.applicationId = this.$router.currentRoute.query.id;
            this.ruleForm.version = this.versionId;
            apiAddlist(this.ruleForm).then((res) => {
              if (res.code == 0) {
                this.dialogTableVisible = false;
                this.dataxx();
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.resetForm()
                // this.ruleForm = {
                //   name: "",
                //   method: "",
                //   path: "",
                // };
              } else {
                this.$message.error(res.error);
              }
            });
          }
        } else {
          this.$message.error("请检查输入框中的内容");
          return false;
        }
      });
    },
    //添加接口弹框中的取消事件
    resetForm() {
      this.ruleForm.id = "";
      this.$refs.ruleForm.resetFields();
      this.dialogTableVisible = false;
    },
    //点击接口列表中的编辑按钮事件
    handleClick(index, row) {
      this.dialogTableVisible = true;
      let _row = row;
      this.$nextTick(() => {
        this.ruleForm = Object.assign({}, _row);
      });
    },
    //删除接口列表的事件
    remove(index, row) {
      apiDeletelist({ apiId: row.id }).then(() => {
        this.dataxx();
        this.$message({
          message: "删除成功",
          type: "success",
        });
      });
    },
    //分页的事件
    handleSizeChange(val) {
      this.size = val;
      this.dataxx();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.dataxx();
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-dialog {
  width: 25%;
  min-width: 400px;
}
@import "@/assets/css/global.less";
</style>
