import request from './request.js';
import public_request from './public_request'
//获取元素列表
export function elementlist({ pageId }) {
    return request({
        url: '/application-management/page/elements',
        method: 'get',
        params: public_request({
            pageId
        })
    })
}
//新增元素列表
export function elementAddlist({
    pageId,
    pid,
    name,
    code,
    type,
    apiId,
    remarks,
    extra
}) {
    return request({
        url: '/application-management/page/element',
        method: 'post',
        data: public_request({
            pageId,
            pid,
            name,
            code,
            type,
            apiId,
            remarks,
            extra
        })
    })
}
//获取单个元素信息
export function element({ elementId }) {
    return request({
        url: '/application-management/page/element',
        method: 'get',
        params: public_request({
            elementId
        })
    })
}
//编辑元素
export function elementEnidlist({
    elementId,
    pid,
    name,
    code,
    type,
    apiId,
    remarks,
    extra
}) {
    return request({
        url: '/application-management/page/element',
        method: 'PUT',
        data: public_request({
            elementId,
            pid,
            name,
            code,
            type,
            apiId,
            remarks,
            extra
        })
    })
}
//删除元素
export function elementDeletelist({ elementIds }) {
    return request({
        url: '/application-management/page/elements',
        method: 'DELETE',
        data: public_request({
            elementIds
        })
    })
}
// 获取全部接口列表
export function allApiList(data) {
    return request({
        url: '/application-management/apis-all',
        method: 'get',
        params: public_request(data)
    })
}

