<template>
  <div>
    <!-- 添加一级菜单的按钮 -->
    <p style="margin-bottom:20px">
      <span style="margin-right:15px">菜单列表</span>
      <el-button
        type="primary"
        @click="dialogTableVisible = true"
      >
        添加一级菜单
      </el-button>
    </p>
    <el-drawer
      :title="ruleForm.id ? '编辑菜单' : '新增菜单'"
      :visible.sync="dialogTableVisible"
      direction="rtl"
      size="638px"
      :before-close="resetForm"
    >
      <div class="drawer-content">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
          label-position="top"
        >
          <el-form-item
            label="菜单名称"
            prop="name"
          >
            <el-input
              v-model="ruleForm.name"
              maxlength="32"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="关联页面"
            prop="linkPageId"
          >
            <el-select
              v-model="ruleForm.linkPageId"
              style="width:100%"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in pageCode"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="图标"
            prop
          >
            <ul class="icon">
              <li
                v-for="(item, index) in icon"
                :key="index"
                :class="{active: index === isShow1}"
                @click="editiconXz(index)"
              >
                <i
                  class="iconfont"
                  :class="item"
                />
              </li>
            </ul>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <el-button
            type="primary"
            @click="add"
          >
            保存
          </el-button>
          <el-button @click="resetForm">
            取消
          </el-button>
        </div>
      </div>
    </el-drawer>
    <!-- 树状图 -->
    <div class="block">
      <el-tree
        ref="tree"
        :data="data"
        :props="defaultProps"
        node-key="id"
        default-expand-all
        :expand-on-click-node="false"
      >
        <span
          slot-scope="{node, data}"
          class="custom-tree-node"
        >
          <i
            class="iconfont"
            :class="data.icon"
          />
          <span class="text">{{ node.label }}</span>
          <span class="operating">
            <el-button
              type="text"
              size="mini"
              @click="() => append(data)"
            >添加子菜单</el-button>
            <el-button
              type="text"
              size="mini"
              @click="() => editUp(node, data)"
            >编辑</el-button>
            <el-popconfirm
              title="确定要删除这个菜单吗？"
              @confirm="() => remove(node, data)"
            >
              <el-button
                slot="reference"
                type="text"
                size="mini"
                style="color:red;margin-left:10px"
              >删除</el-button>
            </el-popconfirm>
          </span>
        </span>
      </el-tree>
    </div>
    <!-- 编辑弹框 -->
    <!-- <el-dialog title="编辑菜单" :visible.sync="dialogTableVisible1">
      <el-form
        :model="editForm"
        :rules="rules"
        ref="editForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="菜单名称" prop="name">
          <el-input
            v-model="editForm.name"
            maxlength="32"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="关联页面" prop="linkPageId">
          <el-select
            v-model="editForm.linkPageId"
            clearable
            placeholder="请选择"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in pageCode"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择图标" prop>
          <ul class="icon">
            <li
              v-for="(item, index) in icon"
              :key="index"
              @click="editiconXz(index)"
              :class="{ active: index === isShow1 }"
            >
              <i class="iconfont" :class="item"></i>
            </li>
          </ul>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="edit('editForm')">保存</el-button>
          <el-button @click="editCancel('editForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog> -->
  </div>
</template>
<script>
import {
  menulist,
  menuAddlist,
  menuEditlist,
  menuDeletelist,
} from "@/api/management_menu";
import { allPageList } from "@/api/management_page";
export default {
  data() {
    return {
      versionId: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      dialogTableVisible: false,
      ruleForm: {
        name: "",
        linkPageId: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入菜单名称", trigger: "blur" },
          { min: 1, max: 32, message: "长度在32个字符以内", trigger: "blur" },
          { validator: this.formValidation.menusName, trigger: "blur" },
        ],
      },
      icon: [
        "",
        "icon-gangweijiaose",
        "icon-mima",
        "icon-kehuguanli",
        "icon-tuanduikehu",
        "icon-xiansuochi",
        "icon-renshiguanli",
        "icon-gonghaixiansuo",
        "icon-zuzhijiagou",
        "icon-gonghaikehu",
        "icon-wodekehu",
        "icon-wodexiansuo",
        "icon-shezhi",
        "icon-kaoqinshezhi",
        "icon-xitongshezhi",
        "icon-zhuye",
        "icon-zuoxihao",
      ],
      isShow: NaN,
      dialogTableVisible1: false,
      editForm: "",
      isShow1: NaN,
      sonData: "",
      pageCode: "",
    };
  },
  mounted() {},
  methods: {
    init(id) {
      console.log(id);
      this.versionId = id;
      this.getMenusList();
    },
    getMenusList() {
      //渲染菜单列表
      menulist({
        applicationId: this.$router.currentRoute.query.id,
        version: this.versionId,
      }).then((res) => {
        this.data = res.data.list;
      });
      allPageList({
        version: this.versionId,
        applicationId: this.$router.currentRoute.query.id,
      }).then((res) => {
        this.pageCode = res.data.list;
      });
    },
    //添加子菜单
    append(data) {
      this.sonData = data;
      this.dialogTableVisible = true;
    },
    //删除
    remove(node, data) {
      let arr = [];
      arr.push(data.id);
      this.find2(data, arr);
      menuDeletelist({ menuIds: arr }).then(() => {
        // menulist({
        //   applicationId: this.$router.currentRoute.query.id,
        // }).then((res) => {
        //   this.data = res.data.list;
        // });
        this.getMenusList();
        this.$message({
          message: "保存成功",
          type: "success",
        });
      });
    },
    //收集子级的id
    find2(data, arr) {
      for (let i = 0; i < data.children.length; i++) {
        arr.push(data.children[i].id);
        if (data.children[i].children.length > 0) {
          this.find2(data.children[i], arr);
        }
      }
    },
    //查找父级id
    find(index, arr) {
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr[i].children.length; j++) {
          if (index === arr[i].children[j].id) {
            return arr[i].id;
          } else if (arr[i].children && arr[i].children.length > 0) {
            this.find(index, arr[i].children);
          }
        }
      }
    },
    //打开编辑弹框并赋值
    editUp(node, data) {
      this.dialogTableVisible = true;
      let _row = data;
      if (_row.linkPageId == 0) _row.linkPageId = "";
      this.$nextTick(() => {
        this.ruleForm = Object.assign({}, _row); // editForm是Dialog弹框的data
        for (let i = 0; i < this.icon.length; i++) {
          if (this.ruleForm.icon === this.icon[i]) {
            this.isShow1 = i;
            break;
          }
        }
        for (let i = 0; i < this.pageCode.length; i++) {
          if (this.ruleForm.linkPageCode === this.pageCode[i].code) {
            this.ruleForm.linkPageCode = this.pageCode[i].name;
            break;
          }
        }
      });
    },
    //编辑保存事件
    edit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
        } else {
          this.$message.error("请检查输入框中的内容");
          return false;
        }
      });
    },
    //取消事件
    editCancel(formName) {
      this.$refs[formName].resetFields();
      this.dialogTableVisible1 = false;
    },
    //编辑图标选择
    editiconXz(c) {
      this.isShow1 = c;
      this.ruleForm.iconfont = this.icon[c];
      // console.log('this.ruleForm',this.ruleForm)
    },
    //图表选择
    iconXz(c) {
      this.isShow = c;
    },
    //添加菜单
    add() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.ruleForm.id) {
            let pidc = "";
            for (let i = 0; i < this.data.length; i++) {
              if (this.data[i].id === this.ruleForm.id) {
                pidc = 0;
                break;
              } else {
                pidc = this.find(this.ruleForm.id, this.data);
              }
            }
            for (let i = 0; i < this.pageCode.length; i++) {
              if (this.ruleForm.linkPageCode === this.pageCode[i].name) {
                this.ruleForm.linkPageCode = this.pageCode[i].id;
                break;
              }
            }
            let formData = {
              menuId: this.ruleForm.id,
              pid: pidc,
              name: this.ruleForm.name,
              linkPageId: this.ruleForm.linkPageId,
              icon: this.icon[this.isShow1],
            }
            console.log('formData',formData)
            menuEditlist(formData).then((res) => {
              if (res.code == 0) {
                menulist({
                  applicationId: this.$router.currentRoute.query.id,
                  version: this.versionId,
                }).then((res) => {
                  this.data = res.data.list;
                });
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
                // this.dialogTableVisible = false;
                this.resetForm();
              } else {
                this.$message.error(res.error);
              }
            });
          } else {
            // this.dialogTableVisible = false;
            let pidc = 0;
            if (this.sonData !== "") pidc = this.sonData.id;
            let form = {
              version: this.versionId,
              applicationId: this.$router.currentRoute.query.id,
              pid: pidc,
              name: this.ruleForm.name,
              linkPageId: this.ruleForm.linkPageId,
              icon: this.ruleForm.iconfont,
            };
            menuAddlist(form).then((res) => {
              if (res.code == 0) {
                this.sonData = "";
                this.getMenusList();
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.resetForm();
                this.isShow = NaN;
              } else {
                this.$message.error(res.error);
              }
            });
          }
        } else {
          this.$message.error("请检查输入框中的内容");
          return false;
        }
      });
    },
    //添加页面弹框中的取消事件
    resetForm() {
      this.ruleForm.id = "";
      this.isShow1 = "";
      this.dialogTableVisible = false;
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/css/global.less";
::v-deep .el-tree-node__content {
  position: relative;
  i {
    font-weight: 900;
    width: 16px;
    height: 16px;
    display: inline-block;
  }
  .text {
    font-size: 14px;
    margin-left: 6px;
  }
}
.operating {
  position: absolute;
  right: 30px;
  top: 5px;
}
.icon {
  li {
    vertical-align: -11px;
    line-height: normal;
    list-style: none;
    display: inline-block;
    border: 1px solid #666;
    width: 45px;
    height: 45px;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 20px;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 16px;
    }
  }
  // :nth-child(9n){
  //    margin-right: 0px;
  // }
  .active {
    border: 1px solid red;
  }
}
::v-deep .el-tree-node__content {
  border: 1px solid #ebeef5;
  padding-top: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid transparent;
}
::v-deep .el-tree {
  height: 100%;
  border-bottom: 1px solid #ebeef5;
}
::v-deep .el-dialog {
  width: 25%;
  min-width: 400px;
}
.block {
  border: 1px solid #ebeef5;
  height: calc(100vh - 315px);
  overflow-y: auto;
}
</style>
