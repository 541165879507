import request from './request.js';
import public_request from './public_request'
//获取功能列表
export function functionslist({ applicationId,version,pageNo,pageSize }) {
    return request({
        url: '/application-management/functions',
        method: 'get',
        params: public_request({
            applicationId,
            version,
            pageNo,
            pageSize
        })
    })
}
//新增功能
export function functionsAddlist({ applicationId,name,version,apiId,remarks }) {
    return request({
        url: '/application-management/function',
        method: 'post',
        data: public_request({
            applicationId,
            name,
            version,
            apiId,
            remarks
        })
    })
}
//编辑功能
export function functionsEditlist({ functionId,name,apiId,remarks }) {
    return request({
        url: '/application-management/function',
        method: 'put',
        data: public_request({
            functionId,
            name,
            apiId,
            remarks
        })
    })
}
//删除功能
export function functionsDeletelist({ functionId }) {
    return request({
        url: '/application-management/function',
        method: 'DELETE',
        data: public_request({
            functionId
        })
    })
}