<template>
  <div>
    <p style="margin-bottom:20px;">
      <span style="margin-right:20px">功能列表</span>
      <el-button
        type="primary"
        @click="dialogTableVisible = true"
      >
        添加功能
      </el-button>
    </p>
    <el-drawer
      :title="ruleForm.id ? '编辑功能' : '新增功能'"
      :visible.sync="dialogTableVisible"
      direction="rtl"
      size="638px"
      :before-close="resetForm"
    >
      <div class="drawer-content">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
          label-position="top"
        >
          <el-form-item
            label="功能名称"
            prop="name"
          >
            <el-input
              v-model="ruleForm.name"
              maxlength="32"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="接口"
            prop="apiId"
          >
            <el-select
              v-model="ruleForm.apiId"
              style="width:100%"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in APIlist"
                :key="item.number"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="备注"
            prop="remarks"
          >
            <el-input
              v-model="ruleForm.remarks"
              type="textarea"
              maxlength="128"
              show-word-limit
            />
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <el-button
            type="primary"
            @click="submitForm"
          >
            保存
          </el-button>
          <el-button @click="resetForm">
            取消
          </el-button>
        </div>
      </div>
    </el-drawer>
    <!-- 功能列表渲染 -->
    <el-table
      v-loading="loading"
      v-tableHeight="{bottomOffset: 115}"
      :data="tableData"
      :header-cell-style="styleObj"
      border
      style="width: 100%"
      height="100px"
    >
      <el-table-column
        prop="name"
        label="功能名称"
      />
      <el-table-column
        prop="apiName"
        label="接口"
      />
      <el-table-column
        prop="remarks"
        label="备注"
      />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="medium"
            @click="handleClick(scope.$index, scope.row)"
          >
            编辑
          </el-button>
          <el-popconfirm
            title="确定要删除这个功能吗？"
            @confirm="remove(scope.$index, scope.row)"
          >
            <el-button
              slot="reference"
              type="text"
              size="medium"
              style="color:red;margin-left:10px"
            >
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 功能列表分页 -->
    <div
      class="block"
      style="margin-top:20px;text-align:right"
    >
      <el-pagination
        :current-page="page"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="len"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import {
  functionslist,
  functionsAddlist,
  functionsEditlist,
  functionsDeletelist,
} from "../../../../api/management_functions.js";
import { allApiList } from "../../../../api/management_elements";
export default {
  data() {
    return {
      versionId: "",
      styleObj: {
        background: "#f5f7fa",
      },
      tableData: [],
      dialogTableVisible: false,
      ruleForm: {
        name: "",
        apiId: "",
        remarks: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入功能名称", trigger: "blur" },
          { min: 1, max: 32, message: "长度在32个字符以内", trigger: "blur" },
          { validator: this.formValidation.pagename, trigger: "blur" },
        ],
      },
      dialogTableVisible1: false,
      editForm: "",
      APIlist: [],
      page: 1,
      len: 0,
      size: 10,
      loading: true,
    };
  },
  mounted() {
    // this.xylist();
    // interfacelist({
    //   applicationId: this.$router.currentRoute.query.id,
    //   pageNo: 1,
    //   pageSize: 50,
    // }).then((res) => {
    //   this.APIlist = res.data.list;
    // });
  },
  methods: {
    init(id) {
      console.log(id);
      this.versionId = id;
      this.xylist();
      allApiList({
        applicationId: this.$router.currentRoute.query.id,
        version: this.versionId,
      }).then((res) => {
        this.APIlist = res.data.list;
      });
    },
    //渲染功能列表
    xylist() {
      functionslist({
        applicationId: this.$router.currentRoute.query.id,
        version: this.versionId,
        pageNo: this.page,
        pageSize: this.size,
      }).then((res) => {
        this.tableData = res.data.list;
        this.len = res.data.total;
      });
      this.loading = false;
    },
    //添加功能弹框中的保存事件
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.ruleForm.id) {
            this.ruleForm.functionId = this.ruleForm.id;
            // for (let i = 0; i < this.APIlist.length; i++) {
            //   if (this.ruleForm.apiName === this.APIlist[i].name) {
            //     this.ruleForm.apiId = this.APIlist[i].id;
            //     break;
            //   } else {
            //     this.ruleForm.apiId = this.ruleForm.apiName;
            //   }
            // }
            console.log(this.ruleForm);
            functionsEditlist(this.ruleForm).then((res) => {
              if (res.code == 0) {
                // this.dialogTableVisible = false;
                this.resetForm();
                this.xylist();
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
              } else {
                this.$message.error(res.error);
              }
            });
          } else {
            this.ruleForm.applicationId = this.$router.currentRoute.query.id;
            this.ruleForm.version = this.versionId;
            functionsAddlist(this.ruleForm).then((res) => {
              if (res.code == 0) {
                // this.dialogTableVisible = false;
                this.resetForm();
                this.xylist();
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                // this.ruleForm = {
                //   name: "",
                //   apiId: "",
                //   remarks: "",
                // };
              } else {
                this.$message.error(res.error);
              }
            });
          }
        } else {
          this.$message.error("请检查输入框中的内容");
          return false;
        }
      });
    },
    //添加功能弹框中的取消事件
    resetForm() {
      this.ruleForm.id = "";
      this.$refs.ruleForm.resetFields();
      this.dialogTableVisible = false;
    },
    //点击页面设置列表中的编辑按钮事件
    handleClick(index, row) {
      this.dialogTableVisible = true;
      let _row = row;
      if (_row.apiId == 0) _row.apiId = "";
      //将每一行的数据赋值给Dialog弹框（这里是重点）
      this.$nextTick(() => {
        this.ruleForm = Object.assign({}, _row); // editForm是Dialog弹框的data
      });
    },
    //删除页面设置列表的事件
    remove(index, row) {
      functionsDeletelist({ functionId: row.id }).then(() => {
        this.xylist();
        this.$message({
          message: "删除成功",
          type: "success",
        });
      });
    },
    //分页的事件
    handleSizeChange(val) {
      this.size = val;
      this.xylist();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.xylist();
    },
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/css/global.less";
::v-deep .el-dialog {
  width: 25%;
  min-width: 400px;
}
</style>
