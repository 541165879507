<template>
  <el-row :gutter="24">
    <!-- 页面设置模块 -->
    <el-col :span="14">
      <p style="margin-bottom:10px;">
        <span style="margin-right:20px">页面列表</span>
        <el-button
          type="primary"
          @click="dialogTableVisible = true"
        >
          添加页面
        </el-button>
      </p>

      <el-drawer
        :title="ruleForm.id ? '编辑页面' : '新增页面'"
        :visible.sync="dialogTableVisible"
        direction="rtl"
        size="638px"
        :before-close="resetForm"
      >
        <div class="drawer-content">
          <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            label-width="100px"
            class="demo-ruleForm"
            label-position="top"
          >
            <el-form-item
              label="页面名称"
              prop="name"
            >
              <el-input
                v-model="ruleForm.name"
                maxlength="32"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="页面代码"
              prop="code"
            >
              <el-input
                v-model="ruleForm.code"
                maxlength="32"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="备注"
              prop="remarks"
            >
              <el-input
                v-model="ruleForm.remarks"
                type="textarea"
                maxlength="128"
                show-word-limit
              />
            </el-form-item>
          </el-form>
          <div class="drawer-footer">
            <el-button
              type="primary"
              @click="submitForm"
            >
              保存
            </el-button>
            <el-button @click="resetForm">
              取消
            </el-button>
          </div>
        </div>
      </el-drawer>
      <!-- 页面设置列表渲染 -->
      <el-table
        v-loading="loading"
        v-tableHeight="{bottomOffset: 105}"
        :data="tableData"
        border
        style="width: 100%"
        highlight-current-row
        :header-cell-style="styleObj"
        height="100px"
        @row-click="rowc"
      >
        <el-table-column
          prop="name"
          label="页面名称"
        />
        <el-table-column
          prop="code"
          label="页面代码"
        />
        <el-table-column
          prop="remarks"
          label="备注"
        />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="medium"
              @click="handleClick(scope.$index, scope.row)"
            >
              编辑
            </el-button>
            <el-popconfirm
              title="确定要删除这个页面吗？"
              @confirm="remove(scope.$index)"
            >
              <el-button
                slot="reference"
                type="text"
                size="medium"
                style="color:red;margin-left:10px"
              >
                删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 页面列表分页 -->
      <div
        class="block"
        style="margin-top:10px;text-align:right"
      >
        <el-pagination
          :current-page="page"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="len"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-col>
    <!-- 页面元素部分 -->
    <el-col :span="10">
      <p style="margin-bottom:10px;">
        <span style="margin-right:20px">页面元素</span>
        <el-button
          :disabled="tableData.length > 0 ? false : true"
          type="primary"
          @click="dialogTableVisible2 = true"
        >
          添加元素
        </el-button>
      </p>

      <el-drawer
        :title="elementForm.elementId ? '编辑元素' : '新增元素'"
        :visible.sync="dialogTableVisible2"
        direction="rtl"
        size="638px"
        :before-close="cancel"
      >
        <div class="drawer-content">
          <el-form
            ref="elementForm"
            :model="elementForm"
            :rules="rules1"
            label-width="100px"
            class="demo-ruleForm"
            label-position="top"
          >
            <el-form-item
              label="元素名称"
              prop="name"
            >
              <el-input
                v-model="elementForm.name"
                maxlength="32"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="代码"
              prop="code"
            >
              <el-input
                v-model="elementForm.code"
                maxlength="32"
                show-word-limit
              />
            </el-form-item>
            <el-form-item
              label="类型"
              prop="type"
            >
              <el-select
                v-model="elementForm.type"
                style="width:100%"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in typeXz"
                  :key="item.number"
                  :label="item.cnVal"
                  :value="item.number"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="API"
              prop="apiId"
            >
              <el-select
                v-model="elementForm.apiId"
                style="width:100%"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in APIlist"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="附加信息"
              prop="extra"
            >
              <el-input
                v-model="elementForm.extra"
                placeholder="请输入json格式信息"
                type="textarea"
                :autosize="{minRows: 4}"
              />
            </el-form-item>
            <el-form-item
              label="备注"
              prop="remarks"
            >
              <el-input
                v-model="elementForm.remarks"
                type="textarea"
                maxlength="128"
                show-word-limit
              />
            </el-form-item>
            <!-- <el-form-item>
            <el-button type="primary" @click="add('elementForm')"
              >保存</el-button
            >
            <el-button @click="cancel('elementForm')">取消</el-button>
          </el-form-item> -->
          </el-form>
          <div class="drawer-footer">
            <el-button
              type="primary"
              @click="add"
            >
              保存
            </el-button>
            <el-button @click="cancel">
              取消
            </el-button>
          </div>
        </div>
      </el-drawer>
      <!-- 页面元素列表渲染 -->
      <el-table
        ref="table"
        v-loading="loading1"
        v-tableHeight="{bottomOffset: 105}"
        :data="tableData1"
        :row-key="getRowKeys"
        style="width: 100%;margin-bottom: 20px;"
        border
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        :header-cell-style="styleObj"
        height="100px"
      >
        <el-table-column
          prop
          label="元素名称"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
            <i>({{ scope.row.type1 }})</i>
          </template>
        </el-table-column>
        <el-table-column
          prop
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="medium"
              @click="addSon(scope.row)"
            >
              添加子元素
            </el-button>
            <el-button
              type="text"
              size="medium"
              @click="elementClick(scope.$index, scope.row)"
            >
              编辑
            </el-button>
            <el-popconfirm
              title="确定要删除这个页面元素吗？"
              @confirm="remove1(scope.row)"
            >
              <el-button
                slot="reference"
                type="text"
                size="medium"
                style="color:red;margin-left:10px"
              >
                删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>
<script>
import { list, addlist, enitlist, deletelist } from "@/api/management_page.js";
import {
  elementlist,
  element,
  allApiList,
  elementAddlist,
  elementEnidlist,
  elementDeletelist,
} from "@/api/management_elements";
import { type } from "@/api/public";
export default {
  data() {
    return {
      versionId: "",
      styleObj: {
        background: "#f5f7fa",
      },
      tableData: [],
      dialogTableVisible: false,
      ruleForm: {
        name: "",
        code: "",
        remarks: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入页面名称", trigger: "blur" },
          { min: 1, max: 32, message: "长度最多是32个字符", trigger: "blur" },
          { validator: this.formValidation.pagename, trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入页面代码", trigger: "blur" },
          { min: 1, max: 32, message: "长度最多是32个字符", trigger: "blur" },
          { validator: this.formValidation.vuename, trigger: "blur" },
        ],
      },
      dialogTableVisible1: false,
      editForm: "",
      page: 1,
      len: 0,
      size: 10,
      tableData1: [],
      elementForm: {
        pageId: "",
        pid: "",
        extra:'',
        name: "",
        code: "",
        type: "",
        apiId: "",
        remarks: "",
      },
      dialogTableVisible2: false,
      rules1: {
        name: [
          { required: true, message: "请输入元素名称", trigger: "blur" },
          { min: 1, max: 32, message: "长度最多是32个字符", trigger: "blur" },
          { validator: this.formValidation.pagename, trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入代码", trigger: "blur" },
          { min: 1, max: 32, message: "长度最多是32个字符", trigger: "blur" },
          { validator: this.formValidation.vuename, trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "blur" }],
        extra: [{ validator: this.formValidation.checkJson, trigger: "blur" }],
      },
      editForm1: "",
      indexc1: "",
      dialogTableVisible3: false,
      title: "",
      datac: "",
      getRowKeys(row) {
        return row.id;
      },
      pageId: "",
      APIlist: "",
      typeXz: "",
      sonData: "",
      removelist: "",
      loading: true,
      loading1: true,
    };
  },
  mounted() {
    // let data = {
    //   version: this.versionId,
    //   applicationId: this.$router.currentRoute.query.id,
    //   pageNo: this.page,
    //   pageSize: this.size,
    // };
    // console.log(data);
    // return;
    //页面列表渲染
  },
  methods: {
    init(id) {
      console.log(id);
      this.versionId = id;
      this.getFirstList();
    },
    getFirstList() {
      list({
        version: this.versionId,
        applicationId: this.$router.currentRoute.query.id,
        pageNo: this.page,
        pageSize: this.size,
      }).then((res) => {
        this.tableData = res.data.list;
        if (this.tableData.length > 0) {
          this.len = res.data.total;
          this.pageId = this.tableData[0].id;
          this.title = this.tableData[0].name;
          //元素列表渲染
          elementlist({ pageId: this.tableData[0].id }).then((res) => {
            if (res.code == 0) {
              this.tableData1 = res.data.list;
              this.fy(this.tableData1, this.typeXz);
            } else {
              // this.$message.error(res.message)
            }
          });
        } else {
          this.tableData1 = [];
        }
        this.loading = false;
        this.loading1 = false;
      });
      //API类型列表渲染
      allApiList({
        version: this.versionId,
        applicationId: this.$router.currentRoute.query.id,
      }).then((res) => {
        this.APIlist = res.data.list;
      });
      type({ dictionaryCode: "element-type" }).then((res) => {
        this.typeXz = res.data.list;
      });
    },
    //标签翻译
    fy(data, bj) {
      for (var i = 0; i < data.length; i++) {
        for (var j = 0; j < bj.length; j++) {
          if (data[i].type === bj[j].number) {
            data[i].type1 = bj[j].cnVal;
          }
        }
        if (data[i].children && data[i].children.length > 0) {
          this.fy(data[i].children, bj);
        }
      }
    },
    //点击页面列表渲染元素列表
    rowc(row) {
      this.pageId = row.id;
      this.title = row.name;
      elementlist({ pageId: row.id }).then((res) => {
        this.tableData1 = res.data.list;
        this.fy(this.tableData1, this.typeXz);
        this.loading1 = false;
      });
    },
    //渲染页面列表
    ymlist() {
      list({
        version: this.versionId,
        applicationId: this.$router.currentRoute.query.id,
        pageNo: this.page,
        pageSize: this.size,
      }).then((res) => {
        this.tableData = res.data.list;
        this.len = res.data.total;
      });
    },
    //添加页面弹框中的保存事件
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.ruleForm.id) {
            this.ruleForm.pageId = this.ruleForm.id;
            enitlist(this.ruleForm).then((res) => {
              if (res.code == 0) {
                this.dialogTableVisible = false;
                this.ymlist();
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
              } else {
                this.$message.error(res.error);
              }
            });
          } else {
            this.ruleForm.applicationId = this.$router.currentRoute.query.id;
            this.ruleForm.version = this.versionId;
            addlist(this.ruleForm).then((res) => {
              if (res.code == 0) {
                this.dialogTableVisible = false;
                this.ymlist();
                this.ruleForm = {
                  name: "",
                  vue: "",
                  remarks: "",
                };
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
              } else {
                this.$message.error(res.error);
              }
            });
          }
        } else {
          this.$message.error("请检查输入框中的内容");
          return false;
        }
      });
    },
    //添加页面弹框中的取消事件
    resetForm() {
      this.ruleForm.id = "";
      this.$refs.ruleForm.resetFields();
      this.dialogTableVisible = false;
    },
    //点击页面设置列表中的编辑按钮事件
    handleClick(index, row) {
      this.dialogTableVisible = true;
      let _row = row;
      this.$nextTick(() => {
        this.ruleForm = Object.assign({}, _row);
      });
    },
    //删除页面设置列表的事件
    remove(index) {
      deletelist({ pageId: this.tableData[index].id }).then((res) => {
        if (res.code == 0) {
          this.ymlist();
          this.tableData1 = [];
          this.title = "";
          this.$message({
            message: "删除成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      });
    },
    //分页的事件
    handleSizeChange(val) {
      this.size = val;
      this.ymlist();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.ymlist();
    },
    //新增元素按钮弹框
    add() {
      this.$refs.elementForm.validate((valid) => {
        if (valid) {
          console.log(this.elementForm);
          if (this.elementForm.elementId) {
            for (let i = 0; i < this.tableData1.length; i++) {
              if (this.tableData1[i].id === this.datac.id) {
                this.elementForm.pid = 0;
                break;
              } else {
                this.elementForm.pid = this.find1(
                  this.datac.id,
                  this.tableData1
                );
              }
            }
            elementEnidlist(this.elementForm).then((res) => {
              if (res.code == 0) {
                this.dialogTableVisible2 = false;
                elementlist({ pageId: this.pageId }).then((res) => {
                  this.tableData1 = res.data.list;
                  this.fy(this.tableData1, this.typeXz);
                });
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
              } else {
                this.$message.error(res.error);
              }
            });
          } else {
            this.elementForm.pageId = this.pageId;
            if (this.sonData === "") {
              this.elementForm.pid = 0;
            } else {
              this.elementForm.pid = this.sonData.id;
            }
            elementAddlist(this.elementForm).then((res) => {
              if (res.code == 0) {
                this.dialogTableVisible2 = false;
                elementlist({ pageId: this.pageId }).then((res) => {
                  this.tableData1 = res.data.list;
                  this.fy(this.tableData1, this.typeXz);
                });
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.sonData = "";
                this.elementForm = {
                  pageId: "",
                  pid: "",
                  name: "",
                  code: "",
                  type: "",
                  apiId: "",
                  remark: "",
                };
              } else {
                this.$message.error(res.error);
              }
            });
          }
        } else {
          this.$message.error("请检查输入框中的内容");
          return false;
        }
      });
    },
    //新增元素弹框中的取消事件
    cancel() {
      this.elementForm.elementId = "";
      this.dialogTableVisible2 = false;
      this.$refs.elementForm.resetFields();
    },
    //点击页面设置列表中的编辑按钮事件
    elementClick(index, row) {
      this.dialogTableVisible2 = true;
      let _row = row;
      this.datac = row;
      this.$nextTick(() => {
        this.elementForm = Object.assign({}, _row);
        element({ elementId: row.id }).then((res) => {
          this.elementForm = res.data;
          this.elementForm.elementId = row.id;
          if (this.elementForm.apiId == 0) {
            this.elementForm.apiId = "";
          }
        });
      });
    },
    //查找父级ID的操作
    find1(index, arr) {
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr[i].children.length; j++) {
          if (index === arr[i].children[j].id) {
            return arr[i].id;
          } else if (arr[i].children && arr[i].children.length > 0) {
            this.find1(index, arr[i].children);
          }
        }
      }
    },
    //查找父级的操作
    find3(index, arr) {
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr[i].children.length; j++) {
          if (index === arr[i].children[j].id) {
            return arr[i];
          } else if (arr[i].children && arr[i].children.length > 0) {
            this.find1(index, arr[i].children);
          }
        }
      }
    },
    //删除页面元素事件
    remove1(data) {
      let arr = [];
      arr.push(data.id);
      this.find2(data, arr);
      elementDeletelist({ elementIds: arr }).then((res) => {
        if (res.code == 0) {
          elementlist({ pageId: this.pageId }).then((res) => {
            this.tableData1 = res.data.list;
            this.fy(this.tableData1, this.typeXz);
            this.$message({
              message: "删除成功",
              type: "success",
            });
          });
        } else {
          this.$message({
            message: res.message,
            type: "error  ",
          });
        }
      });
    },
    //收集子级的id
    find2(data, arr) {
      for (let i = 0; i < data.children.length; i++) {
        arr.push(data.children[i].id);
        if (data.children[i].children.length > 0) {
          this.find2(data.children[i], arr);
        }
      }
    },
    //递归查找控制删除和编辑的位置的函数
    find(data, arr, newText) {
      let index = data.id;
      for (let i = 0; i < arr.length; i++) {
        if (index === arr[i].id) {
          if (newText) {
            arr.splice(i, 1, newText);
          } else {
            arr.splice(i, 1);
          }
          break;
        } else if (arr[i].children && arr[i].children.length > 0) {
          this.find(data, arr[i].children, newText);
        }
      }
    },
    //页面元素中添加子元素事件
    addSon(data) {
      console.log(data);
      this.dialogTableVisible2 = true;
      this.sonData = data;
      // for (let i = 0; i < this.tableData1.length; i++) {
      //   if (this.tableData1[i].id === data.id) {
      //     this.sonData = data;
      //     break;
      //   } else {
      //     this.sonData = this.find3(data.id, this.tableData1);
      //   }
      // }
    },
  },
};
</script>
<style scoped lang="less">
@import "@/assets/css/global.less";
i {
  font-style: normal;
  color: #999;
  margin-left: 7px;
}
</style>
