import request from './request.js';
import public_request from './public_request'
// 
export function allPageList(data) {
    return request({
        url: '/application-management/pages',
        method: 'get',
        params: public_request(data)
    })
}
//获取页面列表
export function list({applicationId,version, pageNo, pageSize}) {
    return request({
        url: '/application-management/pages',
        method: 'get',
        params: public_request({
            applicationId,
            version,
            pageNo,
            pageSize
        })
    })
}
//新增页面
export function addlist({applicationId,version, name, code, remarks}) {
    return request({
        url: '/application-management/page',
        method: 'post',
        data: public_request({
            applicationId,
            version,
            name,
            code,
            remarks
        })
    })
}
//编辑页面
export function enitlist({pageId, name, code, remarks}) {
    return request({
        url: '/application-management/page',
        method: 'put',
        data: public_request({
            pageId,
            name,
            code,
            remarks
        })
    })
}
//删除页面
export function deletelist({pageId}) {
    return request({
        url: '/application-management/page',
        method: 'DELETE',
        data: public_request({
            pageId
        })
    })
}